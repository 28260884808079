export const shuangmianTB=[
    { tabType: 0, title: "佰" },
    { tabType: 1, title: "拾" },
    { tabType: 2, title: "個" },
    { tabType: 3, title: "佰拾和數 OOX" },
    { tabType: 4, title: "佰個和數 OXO" },
    { tabType: 5, title: "拾個和數 XOO" },
    { tabType: 6, title: "佰拾個和數 OOO" },
    { tabType: 7, title: "佰拾個和數尾數" },
  ]

  export const yzdwTB=[
    { tabType: 0, title: "佰" },
    { tabType: 1, title: "拾" },
    { tabType: 2, title: "個" }
  ]