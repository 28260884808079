// 提供復用逻辑的函數（钩子）
import { useIntersectionObserver, useIntervalFn } from '@vueuse/core'
import { ref, onUnmounted, reactive } from 'vue'
import { formatSeconds } from "@/utils/tools"

/**
 * 數據懒加载函數
 * @param {Element} target - Dom對象
 * @param {Function} apiFn - API函數
 */
export const useLazyData = (apiFn) => {
  const result = ref([])
  const target = ref(null)
  // stop 停止观察
  const { stop } = useIntersectionObserver(
    // 监听的目标元素
    target,
    ([{ isIntersecting }], observerElement) => {
      // isIntersecting 是否进入可视区
      if (isIntersecting) {
        stop()
        // 调用API函數获取數據
        apiFn().then(data => {
          result.value = data.result
        })
      }
    },
    // 配置選项，相交的比例大于0就触发
    {
      threshold: 0
    }
  )
  return { result, target }
}

/**
 * 开盤倒计時计數插件
 */
export const timeCountDown = () => {
  let time = ref(0),tmtime = ref(0), timerText = ref(''), tmTimerText = ref(''), cb = ref(null)
  const { pause, resume } = useIntervalFn(() => {
    if (!cb.value && time.value == 0) {
      pause()
    };
    time.value--
    tmtime.value--
    timerText.value = formatSeconds(time.value)
    tmTimerText.value = formatSeconds(tmtime.value)
    if (tmtime.value <= 0) {
      if (cb.value) {
        cb.value();
      }
      pause()
    }
  }, 1000, false)

  onUnmounted(() => {
    pause()
  })

  // 开启定時器 countdown 倒计時時间
  const timerStart = (countdown,countdownTm, callback) => {
    time.value = countdown
    tmtime.value = countdownTm
    cb.value = callback
    timerText.value = formatSeconds(time.value)
    tmTimerText.value = formatSeconds(tmtime.value)
    resume()
  }

  return {
    timerStart,
    timerText,
    tmTimerText
  }
}
