<template>
  <XtxDialog
    :title="title"
    :visible="visible"
    :footer="false"
    @closeDialog="
      () => {
        visible = false;
      }
    "
  >
    <div v-if="loading" class="loading"></div>
    <!-- 彈窗公告列表 -->
    <div class="table-con">
      <table class="m-table" style="border: none !important">
        <tr
          v-for="(item, index) in records.data"
          :key="index"
          style="border: none !important"
          @click="openModalNoticeEvent(item)"
        >
          <td style="color:red;width: 300px; text-align: left; border: none !important">
            {{ item.title }}
          </td>
          <td style="width: 80px; border: none !important">
            {{ formatTime(item.gmtCreate, "YYYY-MM-DD HH:mm:ss") }}
          </td>
        </tr>
        <tr v-if="records.total == 0">
          <td style="width: 100%" colspan="2">暫無數據</td>
        </tr>
      </table>
      <XtxPagination
        v-if="records.total > 0"
        :current-page="query.pageNo"
        :page-size="query.pageSize"
        :total="records.total"
        @current-change="pageChangeEvent"
      />
    </div>
  </XtxDialog>
</template>

<script>
import { reactive, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "ModalNotice",
  props: {
    title: {
      type: String,
      default: "系統通知",
    },
    //数据对象
    records: {
      type: Object,
      default: () => {
        return null;
      },
    },
    pager: {
      type: Object,
      default: () => {
        return { pageNo: 1, pageSize: 10 };
      },
    },
    //是否显示loading
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
          // 使用router
    const router = useRouter();
    const store = useStore();
    let dialog = reactive({
      loading: false,
      record: null,
    });
    /**
     *是否显示弹窗
     */
    let visible = ref(false);
    let query = reactive({
      pageNo: 1,
      pageSize: 20,
    });
    //打开弹窗钩子
    const open = (data) => {
      visible.value = true;
    };
    const pageChangeEvent = ($event) => {
      emit("page-change", $event);
    };
    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(new Date(time))
        .format(format);
    };
    //公告弹窗
    const openModalNoticeEvent = (_item) => {
      if (_item) {
        visible.value = false;
        store.dispatch("app/setNavgationNameAction", "notice");
        router.push(`/game/notice/detail?id=${_item.id}`) 
      }
    };
    return {
      store,
      visible,
      open,
      query,
      pageChangeEvent,
      formatTime,
      openModalNoticeEvent,
    };
  },
};
</script>

<style scoped lang="less">
.xtx-dialog {
  :deep(.wrapper) {
    width: 1000px;
    min-width: 1000px;
    .body {
      font-size: 12px;
      padding: 5px 5px;
    }
  }
}
</style>
